import React, { cloneElement } from "react";
import * as iconCollection from "./svgCollection";

const icons = { ...iconCollection };

export default function CustomIcon({
  iconName,
  className = "",
  style = {},
  ...props
}) {
  // if (!icons[iconName]) {
  //   return new Error(`${iconName} is not found in Icons Collection.`);
  // }
  if (!icons[iconName]) {
    console.warn(`${iconName} is not found in Icons Collection.`);
    return <></>;
  }
  return cloneElement(icons[iconName], {
    className,
    style,
    ...props,
  });
}
