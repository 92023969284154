import React from 'react';
import CustomIcon from '../../../../../../../Assets/CustomIcon';

const info = ({ data, onClose }) => {
  const {
    fullName,
    symbol,
    sector,
    icon,
    amount,
    volume,
    ltp,
    pointChange,
    percentageChange,
    overallPerformer,
    sectorRank,
    demand,
    supply } = data;

  const getFontColor = (value) => {
    let fontColor = 'text-[#4AB8F7]';

    if (value > 0) {
      fontColor = 'text-[#21CD9D]';
    } else if (value < 0) {
      fontColor = 'text-[#E44C4B]';
    }

    return fontColor;
  };

  function formatNumber(number) {
    if (number < 1000) {
      return number;
    } else if (number < 100000) {
      return (number / 1000).toFixed(2) + "k";
    } else if (number < 10000000) {
      return (number / 100000).toFixed(2) + " lac";
    } else if (number < 1000000000) {
      return (number / 10000000).toFixed(2) + " Cr";
    } else {
      return (number / 100000000).toFixed(2) + " Ar";
    }
  }

  const addCommas = (number) => {
    const numStr = number.toString();
    const parts = numStr.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
  }

  return (
    <>
      <div className="flex flex-row justify-between items-start mb-[20px]">
        <div className='flex flex-row'>
          <img
            className="h-[42px] w-[42px] mr-[6px] aspect-square rounded-full overflow-hidden object-center object-cover"
            src={`https://${icon}`}
            alt='logo'
          />
          <p className='flex flex-col w-[200px] gap-[4px] text-sm'>
            <span className='font-semibold text-black'>{fullName}</span>
            <span className='font-extrabold text-black'>{symbol}</span>
          </p>
        </div>
        <CustomIcon iconName={'crossIcon'} className='cursor-pointer fill-black' onClick={onClose} />
      </div>

      <div className='flex flex-row justify-between mb-[16px]'>
        <p className='flex flex-col items-start ml-[12px]'>
          <span className='font-semibold text-black text-[16px]'>{formatNumber(amount)}</span>
          <span className='font-normal text-[#9BA0A4] text-[12px]'>{addCommas(volume)} Shares Traded</span>
        </p>
        <p className='flex flex-col items-end mr-[12px]'>
          <span className='font-semibold text-black text-[16px]'>{ltp.toFixed(2)}</span>
          <span className={`flex font-normal text-[14px] ${getFontColor(pointChange)}`}>
            <span>{pointChange > 0 && '+'}{pointChange?.toFixed(2)}</span>
            <span className='ml-[10px]'>{pointChange > 0 && '+'}{percentageChange?.toFixed(2)}%</span>
          </span>
        </p>
      </div>

      <div className='flex flex-row justify-start items-start gap-[5px] mb-[16px] pl-[20px]'>
        <CustomIcon iconName={'badgeIcon'} className='relative inline-flex' />
        <p className='flex flex-col leading-6'>
          <span className='text-[#9BA0A4] text-[14px]'><span className='text-[#22AB94] font-semibold text-[16px]'>#{overallPerformer}</span> Overall Performer</span>
          <span className='text-[#9BA0A4] text-[14px]'><span className='text-[#22AB94] font-semibold text-[16px]'>#{sectorRank}</span> in {sector} Index</span>
        </p>
      </div>

      <div className='flex justify-between leading-6 px-[8px] py-[9px] rounded-lg blue-gradient mb-[16px]'>
        <p className='text-black text-[14px] font-semibold'>Demand <span className='font-bold ml-[12px]'>{demand ? addCommas(demand) : '-'}</span></p>
        <p className='text-black text-[14px] font-semibold'>Supply <span className='font-bold ml-[12px]'>{supply ? addCommas(supply) : '-'}</span></p>
      </div>
    </>
  )
}

export default info