export function isSystemxLiteRoute() {
    return (window.location.search.startsWith("?tk="));
}

export const Routes = {
    addWatchList: isSystemxLiteRoute() ? "add" : "addSystemx",
    userAllWatchList: isSystemxLiteRoute() ? "userAllWatchList" : "userAllWatchListSystemx",
    userWatchList: isSystemxLiteRoute() ? "userWatchList" : "userWatchListSystemx",
    deleteWatchList: isSystemxLiteRoute() ? "deleteWatchList" : "deleteWatchListSystemx",
    changeTheme: isSystemxLiteRoute() ? "changeTheme" : "changeThemeSystemx",
    getUserTheme: isSystemxLiteRoute() ? "getUserThemeById" : "getUserThemeByIdSystemx",
    timeScaleMarks: isSystemxLiteRoute() ? "showOnChart" : "showOnChartSystemx"
}

