import React from 'react';
import CustomIcon from '../../../../../../Assets/CustomIcon';
import './StockInfo.Styles.css'
import { BrokerInsights, Info, PercentIndicator, TechnicalInsights, Updates } from './components';
import { connect } from 'react-redux';

const StockInfo = ({ onClose, data, loading, theme, isVisible }) => {

  const infoData = {
    fullName: data.fullName,
    symbol: data.symbol,
    sector: data.sector,
    icon: data.icon,
    amount: data.amount,
    volume: data.volume,
    ltp: data.ltp,
    pointChange: data.point_change,
    percentageChange: data.percentage_change,
    overallPerformer: data.overall_performance_rank,
    sectorRank: data.sector_performance_rank,
    demand: data.demand,
    supply: data.supply
  }

  const past5Trading = data.five_period_performance;

  const tradingData = [
    { percent: data.changeInAWeek, time: '1 wk' },
    { percent: data.changeInAMonth, time: '1 month' },
    { percent: data.ChangeInThreeMonths, time: '3 month' },
    { percent: data.changeInOneYear, time: '1 Year' }
  ]

  const technicalInsights = data.technical_insights;

  const updates = data.updates;

  const brokerInsights = data.broker_insights;

  const getStatus = (value) => {
    let status = 'stockNormal';

    if (value > 0) {
      status = 'stockUp';
    } else if (value < 0) {
      status = 'stockDown';
    }
    return status;
  };

  if (loading) {
    return (
      <>
        <div className={theme === 'Dark' ? 'hidden' : 'modal-backdrop'} />
        <div className='loading-background w-full min-[450px]:w-[318px] min-[450px]:right-[48px]' />
        <div className="centered-element text-black">
          Loading...
        </div>
      </>
    );
  }

  return (
    <>
      <div className={theme === 'Dark' ? 'modal-backdrop-dark' : 'modal-backdrop-light'} onClick={onClose} />
      <div className={`fixed z-30 w-full min-[450px]:right-[48px] min-[450px]:w-[318px] top-[52px] px-[11px] py-[16px] h-[95vh] overflow-y-auto custom-scrollbar bg-[#FFF] rounded-[16px]`}>

        <Info data={infoData} onClose={onClose} />

        <div className="flex flex-row justify-between items-center gap-[12px] pb-[13px]">
          <p className='font-semibold text-black text-[14px]'>Past 5 trading sessions</p>
          <div className='flex flex-row justify-start items-center gap-[4px]'>
            {past5Trading?.map((value, index) => (
              <CustomIcon
                key={index}
                iconName={getStatus(value.point_change)}
                className='pulse-animation'
                style={{ animationDelay: `${index * 300}ms` }}
              />
            ))}
          </div>
        </div>

        <div className="flex flex-row justify-center items-center gap-[4px] mb-[34px]">
          {tradingData.map((data, index) => (
            <PercentIndicator data={data} key={index} />
          ))}
        </div>

        <div className="flex flex-col gap-[13px] mb-[55px]">
          {technicalInsights &&
            technicalInsights.map((data, index) => (
              <TechnicalInsights key={index} data={data} />
            ))
          }

          {updates &&
            updates.map((data, index) => (
              <Updates key={index} data={data} />
            ))
          }

          {brokerInsights &&
            brokerInsights.map((data, index) => (
              <BrokerInsights key={index} data={data} />
            ))
          }
        </div>

      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
  };
};

export default connect(mapStateToProps)(StockInfo)