/* eslint-disable */

import * as React from "react";
import "./index.css";
import { connect } from "react-redux";
import { widget } from "../../charting_library";
import { getUserId } from "../../components/TVChartContainer";
import {
  fetchBullishAndBearish,
  getCandlePatternInfo,
  fetchSupportResistance,
} from "../../store/actionsType";
import * as actionTypes from "../../store/actionsType";
import configureSymbol from "../../chartUtils";

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function getUrl() {
  const env_var =
    typeof window._env_ !== "undefined" ? window._env_ : process.env;
  return env_var.REACT_APP_CHART_URL;
}

function getAuth() {
  if (
    localStorage.getItem("persist:systemx-lt-") !== undefined &&
    localStorage.getItem("persist:systemx-lt-") !== null
  ) {
    return JSON.parse(
      JSON.parse(localStorage.getItem("persist:systemx-lt-")).auth
    ).userId;
  } else {
    return null;
  }
}

class NewUpdatedChartContainerSystemx extends React.PureComponent {
  static defaultProps = {
    symbol: "NEPSE",
    interval: "D",
    userId: getUserId(),
    containerId: "tv_chart_container",
    datafeedUrl: getUrl() + "/tv",
    libraryPath: "/charting_library/",
    chartsStorageUrl: getUrl() + "/systemxChart/saveChart",
    chartsStorageApiVersion: "1.1",
    clientId: "systemx.tradingview.com",
    fullscreen: true,
    autosize: true,
    studiesOverrides: {},
    timezone: "Asia/Kathmandu",
    width: 500,
    height: 500,
  };

  tvWidget = null;
  supportAndResistanceLineIdList = [];

  checkSymbolState = (symbol, isAdjusted) =>
    symbol + (isAdjusted === true ? "_adj" : "");

  componentDidMount() {
    const widgetOptions = {
      symbol: localStorage.getItem("symbol") || "NEPSE",
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
        this.props.datafeedUrl
      ),
      interval: this.props.interval,
      container: this.props.containerId,
      library_path: this.props.libraryPath,

      symbol_search_request_delay: 1000,

      locale: getLanguageFromURL() || "en",
      enabled_features: ["study_templates", "use_localstorage_for_settings"],

      charts_storage_url: this.props.chartsStorageUrl,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      save_chart_properties_to_local_storage: true,
      user_id: localStorage.getItem("userId"),
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      timezone: "exchange",
      studies_overrides: this.props.studiesOverrides,
      theme: localStorage.getItem("tvTheme"),
      overrides: {
        timezone: "Asia/Kathmandu",
      },
    };

    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        document.querySelector("#loadChart")?.addEventListener("click", () => {
          tvWidget.activeChart().clearMarks();
          tvWidget.activeChart().refreshMarks();
        });
        const button = tvWidget.createButton();
        button.setAttribute("title", "Adjusted Button");
        button.classList.add("apply-common-tooltip");

        button.addEventListener("click", () => {
          let currentSymbol = tvWidget.activeChart().symbol().toLowerCase();
          currentSymbol = configureSymbol(currentSymbol);
          if (currentSymbol.includes("_adj")) {
            const stockSymbol = currentSymbol.split("_")[0];
            tvWidget.activeChart().setSymbol(stockSymbol);
            button.innerHTML = "Adjusted";
            this.props.changeSymbolState(false);
          } else {
            tvWidget.activeChart().setSymbol(currentSymbol + "_adj");
            button.innerHTML = "Unadjusted";
            this.props.changeSymbolState(true);
          }
        });
        button.innerHTML = "Adjusted";
      });
      tvWidget
        .activeChart()
        .onSymbolChanged()
        .subscribe(null, () => {
          let chartSymbol = tvWidget.activeChart().symbol();
          chartSymbol = configureSymbol(chartSymbol);
          chartSymbol = this.checkSymbolState(
            chartSymbol,
            this.props.isAdjusted
          );
          this.props.onSymbolChanged(chartSymbol);
        });
    });
  }

  isEmpty(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  checkStatusOfTickerDivergence() {
    const divergence = this.props.settingState;

    const TIMEOUT = 300;
    if (divergence.rsiBullish) {
      setTimeout(
        () => this.props.fetchDivergence(this.props.symbol, "rsiBullish"),
        TIMEOUT
      );
    } else if (divergence.cciBullish) {
      setTimeout(
        () => this.props.fetchDivergence(this.props.symbol, "cciBullish"),
        TIMEOUT
      );
    } else if (divergence.mfiBullish) {
      setTimeout(
        () => this.props.fetchDivergence(this.props.symbol, "mfiBullish"),
        TIMEOUT
      );
    } else if (divergence.rsiBearish) {
      setTimeout(
        () => this.props.fetchDivergence(this.props.symbol, "rsiBearish"),
        TIMEOUT
      );
    } else if (divergence.cciBearish) {
      setTimeout(
        () => this.props.fetchDivergence(this.props.symbol, "cciBearish"),
        TIMEOUT
      );
    } else if (divergence.mfiBearish) {
      setTimeout(
        () => this.props.fetchDivergence(this.props.symbol, "mfiBearish"),
        TIMEOUT
      );
    } else if (divergence.showSupportResistanceLine) {
      this.tvWidget
        .activeChart()
        .onDataLoaded()
        .subscribe(
          null,
          () =>
            this.props.fetchSupportResistanceData(
              this.props.symbol.split("_")[0]
            ),
          true
        );
    } else {
      setTimeout(
        () => this.props.fetchDivergence(this.props.symbol, ""),
        TIMEOUT
      );
    }
  }
  removeCandlePatternBeforeAnotherPlot() {
    let myCandlePattern = this.props.candlePatternId;
    myCandlePattern.forEach((item) => {
      this.tvWidget.activeChart().removeEntity(item);
    });
  }

  removeTrendLineBeforeAnotherPlot() {
    let myTrendLine = this.props.bearishBullishId;
    myTrendLine.forEach((item) => {
      this.tvWidget.activeChart().removeEntity(item);
    });
  }

  findChannel(state) {
    if (state.cciBearish || state.mfiBearish) {
      return "high";
    } else if (state.cciBullish || state.mfiBullish) {
      return "low";
    } else {
      return "close";
    }
  }

  createSupportResistanceLine(data) {
    if (!this.props.settingState.showSupportResistanceLine) {
      for (let lineData of this.supportAndResistanceLineIdList) {
        this.tvWidget.activeChart().removeEntity(lineData.id);
      }
      return;
    }
    if (typeof data === "object" && typeof data) {
      for (let line of data) {
        const from = Date.now() / 1000 - 20 * 24 * 3600; // 500 days ago
        const to = Date.now() / 1000;
        let srLine = this.tvWidget.activeChart().createMultipointShape(
          [
            { time: from, price: line.bottom },
            { time: to, price: line.bottom },
            { time: to, price: line.top },
          ],
          {
            shape: "parallel_channel",
            lock: true,
            disableSelection: true,
            disableSave: false,
            disableUndo: false,
            overrides: {
              backgroundColor:
                line.type === "support"
                  ? "#c8e6c9"
                  : line.type === "resistance"
                  ? "#fcd7da"
                  : "#b4a7d6",
              extendRight: true,
              extendLeft: true,
              linecolor:
                line.type === "support"
                  ? "#c8e6c9"
                  : line.type === "resistance"
                  ? "#fcd7da"
                  : "#b4a7d6",
              transparency: 20,
              showMidline: false,
              // text: `Resistance Level ${line.channel_strength_level}`,
              // vertLabelsAlign: "bottom",
              // textcolor: "red",
              // showLabel: true,
              // horzLabelsAlign: "right",
            },
          }
        );
        this.supportAndResistanceLineIdList = [
          ...this.supportAndResistanceLineIdList,
          { type: line.type, id: srLine },
        ];
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    try {
      if (
        this.props.supportResistanceData !== prevProps.supportResistanceData
      ) {
        this.createSupportResistanceLine(this.props.supportResistanceData);
      }
      if (prevProps.theme !== this.props.theme) {
        this.tvWidget.changeTheme(localStorage.getItem("tvTheme"));
      }
      if (prevProps.symbol !== this.props.symbol) {
        this.tvWidget.activeChart().setSymbol(this.props.symbol);
        if (this.props.settingState.candlePattern === true) {
          setTimeout(() => this.props.getCandlePattern(this.props.symbol), 400);
        } else {
          setTimeout(() => this.props.getCandlePattern(""), 400);
        }
        this.checkStatusOfTickerDivergence();
        this.createSupportResistanceLine(this.props.supportResistanceData);
      }
      if (prevProps.bearishBullish !== this.props.bearishBullish) {
        try {
          const bearishBullishData = this.props.bearishBullish;
          console.log(bearishBullishData);
          if (!this.isEmpty(bearishBullishData)) {
            // this.tvWidget.activeChart().removeAllShapes();
            this.removeTrendLineBeforeAnotherPlot();
            // 1606608000, to: 1611619200
            const channelName = this.findChannel(this.props.settingState);
            const from = bearishBullishData.from;
            const to = bearishBullishData.to;
            let localStorageForTrendLine = [];
            const trendLine = this.tvWidget.activeChart().createMultipointShape(
              [
                { time: from, channel: channelName },
                { time: to, channel: channelName },
              ],
              {
                shape: "trend_line",
                showInObjectsTree: true,
                overrides: {
                  bold: true,
                  linecolor: "#228ad0",
                  linewidth: 6,
                  // rightEnd: 2.
                  showBarsRange: true,
                  showPriceRange: true,
                  showDateTimeRange: true,
                  textcolor: "red",
                  // leftEnd: 2,
                  italic: true,
                  fontSize: 14,
                  showLabel: true,
                },
              }
            );
            localStorageForTrendLine.push(trendLine);
            this.props.clearBullishBearish(localStorageForTrendLine);
          } else {
            this.removeTrendLineBeforeAnotherPlot();
          }
        } catch (e) {
          console.log(e);
        }
      }
      if (this.props.candlePattern !== prevProps.candlePattern) {
        try {
          let candlePattern = [...this.props.candlePattern];
          if (candlePattern.length !== 0) {
            const localStorageForCandlePattern = [];
            // this.tvWidget.activeChart().removeAllShapes();
            this.removeCandlePatternBeforeAnotherPlot();
            for (let i = 0; i < candlePattern.length; i++) {
              const pattern = this.tvWidget
                .activeChart()
                .createMultipointShape([{ time: candlePattern[i].time }], {
                  shape: "text",
                  text: candlePattern[i].text,
                  overrides: {
                    backgroundColor: "#B0B5B8",
                    bold: true,
                    showLabel: "text",
                    transparency: 20,
                    fillBackground: true,
                    borderColor: "red",
                    color: "blue",
                    fontsize: 10,
                  },
                });
              localStorageForCandlePattern.push(pattern);
              this.tvWidget.activeChart().getShapeById(pattern).bringToFront();
            }
            this.props.clearCandlePattern(localStorageForCandlePattern);
          } else {
            // remove candle pattern of current symbol, and if another candle patterns is ready, then it load .
            this.removeCandlePatternBeforeAnotherPlot();
          }
        } catch (e) {
          console.log(e);
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  componentWillUnmount() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  render() {
    return <div id={this.props.containerId} className={"TVChartContainer"} />;
  }
}

const mapStateToProps = (state) => {
  return {
    symbol: state.symbol,
    theme: state.theme,
    bearishBullish: state.bearishBullish,
    candlePattern: state.candlePattern,
    settingState: state.settingState,
    bearishBullishId: state.bearishBullishId,
    candlePatternId: state.candlePatternId,
    isAdjusted: state.isAdjusted,
    supportResistanceData: state.supportResistanceData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserTheme: (userTheme) =>
      dispatch({ type: actionTypes.CHANGE_THEME, theme: userTheme }),
    onSymbolChanged: (symbol) =>
      dispatch({ type: actionTypes.CHANGE_SYMBOL, symbol: symbol }),
    changeSymbolState: (isAdjusted) =>
      dispatch({ type: actionTypes.IS_ADJUSTED, isAdjusted }),
    fetchDivergence: (symbol, technicalName) =>
      dispatch(fetchBullishAndBearish(symbol, technicalName)),
    getCandlePattern: (symbol) => dispatch(getCandlePatternInfo(symbol)),
    saveSettingState: (settingState) =>
      dispatch({ type: actionTypes.SAVE_SETTING_STATE, action: settingState }),
    clearCandlePattern: (candlePatternId) =>
      dispatch({
        type: actionTypes.CLEAR_CANDLE_PATTERN,
        candlePatternId: candlePatternId,
      }),
    clearBullishBearish: (bullishBearishId) =>
      dispatch({
        type: actionTypes.CLEAR_BULLISH_BEARISH,
        bearishBullishId: bullishBearishId,
      }),
    fetchSupportResistanceData: (symbol) => {
      dispatch(fetchSupportResistance(symbol));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewUpdatedChartContainerSystemx);
