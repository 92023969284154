import React from 'react'
import CustomIcon from '../../../../../../../Assets/CustomIcon';

const TechnicalInsights = ({ data }) => {

  const colorsAndIcons = {
    bullish: {
      iconBgColor: 'bg-[#F0FAF8]',
      bgColor: 'bg-[#F1F3FA]',
      iconName: 'bullIcon',
    },
    bearish: {
      iconBgColor: 'bg-[#FFF2F3]',
      bgColor: 'bg-[#F1F3FA]',
      iconName: 'bearIcon',
    }
  };

  const { iconBgColor, bgColor, iconName } = colorsAndIcons[data.icon];

  return (
    <div className={`flex flex-row items-start px-[8px] py-[8px] w-full min-h-[76px] ${bgColor} rounded-lg`}>
      <span className={`${iconBgColor} flex justify-center items-center rounded-full p-[3px] mr-[7px]`}>
        <CustomIcon iconName={iconName} />
      </span>
      <div className='flex flex-col items-start'>
        <p className='text-[14px] text-black font-semibold leading-normal mb-[6px]'>{data.title}</p>
        <p className='text-[12px] font-normal text-[#424242]'>{data.description}</p>

        <p
          className='text-[12px] font-light italic text-[#7C8287] mt-[10px]'
          dangerouslySetInnerHTML={{ __html: data.body }}>
        </p>
      </div>
    </div>
  )
}

export default TechnicalInsights