import React, { useEffect } from 'react'
import './MultiCharts.Style.css'
import NotFound from '../components/NotFound';
import MultiChartContainer from './MultiChartContainer'

const MultiCharts = () => {

  useEffect(() => {
    localStorage.setItem("source", 'systemxLite');
  }, []);

  const getAuth = () => {
    if (
      localStorage.getItem("persist:systemx-lt-") !== undefined &&
      localStorage.getItem("persist:systemx-lt-") !== null
    ) {
      return JSON.parse(
        JSON.parse(localStorage.getItem("persist:systemx-lt-")).auth
      ).userId;
    } else {
      return null;
    }
  };

  if (getAuth() === null) {
    return <NotFound />
  }

  return (
    <>
      <div className='charts'>
        <div id='chart_1'>
          <MultiChartContainer containerId="chart_container_1" />
        </div>
        <div id='chart_2'>
          <MultiChartContainer containerId="chart_container_2" />
        </div>
        <div id='chart_3'>
          <MultiChartContainer containerId="chart_container_3" />
        </div>
        <div id='chart_4'>
          <MultiChartContainer containerId="chart_container_4" />
        </div>
      </div>
    </>
  )
}

export default MultiCharts