import React from 'react'

const PercentIndicator = ({ data }) => {

  const getBgColorAndFontColor = (percent) => {
    let bgColor = 'bg-[#EBF5FF]';
    let fontColor = 'text-[#4AB8F7]';

    if (percent > 0) {
      bgColor = 'bg-[#F0FAF8]';
      fontColor = 'text-[#21CD9D]';
    } else if (percent < 0) {
      bgColor = 'bg-[#FFF2F3]';
      fontColor = 'text-[#E44C4B]';
    }

    return { bgColor, fontColor };
  };

  const { bgColor, fontColor } = getBgColorAndFontColor(data.percent);

  return (
    data.percent !== null && (
      <div className={`flex flex-col justify-center items-center p-[9px] ml-[4px] min-[550px]:ml-0 min-w-[66px] min-h-[55px] text-[14px] leading-6 rounded-lg ${bgColor}`}>
        <p className={`${fontColor} font-semibold`}>{data.percent > 0 && '+'}{(data.percent * 100)?.toFixed(2)}%</p>
        <p className='text-[#9BA0A4] text-[12px] font-normal'>{data.time}</p>
      </div>
    )
  )
}

export default PercentIndicator
