import React from 'react';
import { Table, ConfigProvider } from 'antd';
import { connect } from 'react-redux';
import CustomIcon from '../../../../../../Assets/CustomIcon';
import './TableComponent.Styles.css';

const TableComponent = ({ onClick, data, theme }) => {
  return (
    <div className='overflow-y-auto custom-scrollbar px-[10px] h-[95vh] sm:px-[5px] sm:h-[80vh] xl:h-[80.9vh] 2xl:h-[88vh]'>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              borderColor: theme === 'Dark' ? '#273037' : '#EFF2F5',
              headerBg: "transparent",
              rowHoverBg: theme === 'Dark' ? '#FFFFFF33' : '#F7F9FC',
              headerSplitColor: "transparent",
              headerColor: "#B2B5BE",
              colorBgContainer: "transparent",
              colorText: theme === "Dark" ? "white" : "black",
              cellPaddingBlock: 10,
            }
          },
        }}
      >
        <Table
          onRow={(record) => ({
            onClick: () => onClick(record.symbol),
            className: 'cursor-pointer'
          })}
          columns={[
            {
              title: " ",
              dataIndex: "status",
              render(_, record) {
                let status = 'stockNormal';
                let fontColor = '#4AB8F7';

                if (parseFloat(record.percentage_change) > 0.00) {
                  status = 'stockUp';
                  fontColor = '#21CD9D';
                } else if (parseFloat(record.percentage_change) < 0.00) {
                  status = 'stockDown';
                  fontColor = '#E44C4B';
                }

                return (
                  <p className='flex justify-center'>
                    <CustomIcon iconName={status} />
                  </p>
                )
              }
            },
            {
              title: 'TKR',
              dataIndex: 'symbol',
              render: (_) => <p className='font-bold'>{_}</p>,
              sorter: (a, b) => {
                try {
                  return a.symbol.localeCompare(b.symbol);
                } catch (error) {
                  return 1;
                }
              },
            },
            {
              title: 'LTP',
              dataIndex: 'ltp',
              render: (_) => <p className='flex justify-end'>{_.toFixed(2)}</p>,
              sorter: (a, b) => {
                try {
                  return a.ltp - b.ltp;
                } catch (error) {
                  return 1;
                }
              },
            },
            {
              title: '%',
              dataIndex: 'percentage_change',
              render: (_, record) => {
                let fontColor = '#4AB8F7';

                if (parseFloat(record.percentage_change) > 0.00) {
                  fontColor = '#21CD9D';
                } else if (parseFloat(record.percentage_change) < 0.00) {
                  fontColor = '#E44C4B';
                }

                return (
                  <p style={{ color: fontColor }} className='flex justify-end'>
                    {`${_.toFixed(2)}%`}
                  </p>
                )
              },
              sorter: (a, b) => {
                try {
                  return (
                    a.percentage_change - b.percentage_change
                  );
                } catch (error) {
                  return 1;
                }
              },
            }
          ]}
          rowClassName={() =>
              (theme === 'Dark' ? 'table-row-dark' : 'table-row-light')
          }
          className='rounded-lg table-header-no-border'
          rowKey={(record) => record.symbol}
          dataSource={data}
          pagination={false}
        />
      </ConfigProvider>
    </div>
  )
}

const mapStateToProps = (state) => ({
  theme: state.theme
});

export default connect(mapStateToProps)(TableComponent)
