import React, { useState } from 'react';
import { connect } from 'react-redux';
import './FilterModal.Styles.css'

const FilterModal = ({ filterClick, theme, isActive, setActive, reset }) => {
  const data = [
    { label: 'Top Gainers', value: 'top_gainers' },
    { label: 'Top Losers', value: 'top_losers' },
    { label: 'Top Turnover', value: 'top_turnover' },
    { label: 'Top Volume', value: 'top_volume' },
    { label: 'Top Transaction', value: 'top_transaction' },
    { label: 'Close to 52 Wk High', value: 'close_to_52_wk_high' },
    { label: 'Close to 52 Wk Low', value: 'close_to_52_wk_low' },
    { label: 'Trending', value: 'trending' },
    { label: 'Highest Demand', value: 'highest_demand' },
    { label: 'Highest Supply', value: 'highest_supply' },
  ];

  const [activeIndex, setActiveIndex] = useState(isActive);

  const handleButtonClick = (index, value) => {
    if (activeIndex === index) {
      reset();
      setActive('');
      setActiveIndex('');
    } else {
      setActiveIndex(index);
      setActive(index, value);
      filterClick(value);
    }
  };

  return (
    <div className={`fixed z-10 w-full min-[450px]:w-[318px] min-[450px]:right-[48px] top-[52px] h-screen rounded-[16px] ${theme === "Dark" ? 'bg-[#101920]' : 'bg-[#F0F3F9]'}`}>
      <div className='flex justify-end items-end pr-[20px] pb-[150px] h-full'>
        <div className='flex flex-col items-end w-[170px]'>
          {data.map((item, index) => (
            <button
              key={index}
              onClick={() => handleButtonClick(index, item.value)}
              className={`cursor-pointer ${theme === "Dark" ? 'bg-[#2A2E39] text-white' : 'text-black bg-white'} text-[14px] 2xl:text-[15px] px-[16px] py-[6px] mb-2 2xl:mb-6 rounded-xl shadow-sm animate-button ${activeIndex === index ? (theme === "Dark" ? 'bg-white text-[#000000]' : 'bg-black text-white') : ''}`}
              style={{ animationDelay: `${index * 30}ms` }}
            >
              <p>{item.label}</p>
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
  };
};

export default connect(mapStateToProps)(FilterModal)
