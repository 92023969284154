/* eslint-disable */
import React, { useEffect, useRef } from "react";
import { widget } from "../charting_library";
import configureSymbol from "../chartUtils";
import { getUserId } from "../components/TVChartContainer";

function getUrl() {
  const env_var =
    typeof window._env_ !== "undefined" ? window._env_ : process.env;
  return env_var.REACT_APP_CHART_URL;
}

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

const MultiChartContainer = ({
  symbol = "NEPSE",
  interval = "D",
  userId = getUserId(),
  containerId = "MultiChartContainer",
  datafeedUrl = getUrl() + "/tv",
  libraryPath = "/charting_library/",
  chartsStorageApiVersion = "1.1",
  clientId = "systemx.tradingview.com",
  fullscreen = false,
  autosize = true,
  studiesOverrides = {},
  timezone = "exchange",
  chartsStorageUrl,
  width,
  height,
  themes
}) => {
  const prevPropsRef = useRef({symbol});
  const buttonClickedRef = useRef(false);


  useEffect(() => {
    const widgetOptions = {
      symbol: symbol,
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(datafeedUrl),
      symbol_search_request_delay: 1000,
      interval: interval,
      container: containerId,
      library_path: libraryPath,
      locale: getLanguageFromURL() || "en",
      disabled_features: [
        "use_localstorage_for_settings",
        "study_templates",
      ],
      charts_storage_url: chartsStorageUrl,
      charts_storage_api_version: chartsStorageApiVersion,
      client_id: clientId,
      user_id: userId,
      fullscreen: fullscreen,
      autosize: autosize,
      studies_overrides: studiesOverrides,
      timezone: timezone,
      width: width,
      height: height,
      theme: themes,
      toolbar_bg: "#f4f7f7",
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute("title", "Click to change");
        button.classList.add("apply-common-tooltip");

        button.addEventListener("click", () => {
          buttonClickedRef.current = true;
          let currentSymbol = tvWidget
            .activeChart()
            .symbol()
            .toLocaleLowerCase();
          currentSymbol = configureSymbol(currentSymbol);

          if (currentSymbol.includes("_adj")) {
            const stockSymbol = currentSymbol.split("_")[0];
            tvWidget.activeChart().setSymbol(stockSymbol);
            button.innerHTML = "Adjusted";
          } else {
            tvWidget.activeChart().setSymbol(currentSymbol + "_adj");
            button.innerHTML = "Unadjusted";
          }
        });
        button.innerHTML = "Unadjusted";
      });

      tvWidget.activeChart().onSymbolChanged().subscribe(null, () => {
        let currentSymbol = tvWidget.activeChart().symbol().toLocaleLowerCase();
        currentSymbol = configureSymbol(currentSymbol);
        
        if (!buttonClickedRef.current && prevPropsRef.current.symbol !== currentSymbol && !currentSymbol.includes("_index")) {
          prevPropsRef.current.symbol = currentSymbol + "_adj"
          tvWidget.activeChart().setSymbol(currentSymbol + "_adj");
        }
        buttonClickedRef.current = false;
      });
    });

    return () => {
      if (tvWidget !== null) {
        tvWidget.remove();
      }
    };
  }, []);

  return <div id={containerId} className={"MultiChartContainer"} />;
};

export default MultiChartContainer;
