import React, { useState, useEffect } from 'react';
import { Select, ConfigProvider } from 'antd';

const SelectComponent = ({ onValueSelect, data, selected, onClear }) => {
  const { Option } = Select;
  const [selectedValue, setSelectedValue] = useState(selected);

  useEffect(() => {
    setSelectedValue(selected);
  }, [selected]);

  const handleValueSelect = (value) => {
    setSelectedValue(value);
    onValueSelect(value);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            borderRadius: '16px',
            selectorBg: '#F0F3F9',
          }
        },
      }}
    >
      <Select
        value={selectedValue}
        allowClear
        onClear={onClear}
        showSearch
        onChange={handleValueSelect}
        className="w-full"
        placeholder={<span className='text-black'>All Sectors</span>}
      >
        {data.map((sector, index) => (
          <Option key={index} value={sector}>{sector}</Option>
        ))}
      </Select>
    </ConfigProvider>
  )
}

export default SelectComponent;
