import React from "react";
import MultiCharts from "./MultiCharts";

class MultiChartsApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      className: "",
      themeName: "MultiChartsApp",
    };
  }

  render() {
    return <MultiCharts />;
  }
}

export default MultiChartsApp;