import React, { useState, useEffect } from "react";
import { axiosOrder } from "../../../../axios-order";
import CustomIcon from "../../../../Assets/CustomIcon";
import { connect } from "react-redux";
import {
  SelectComponent,
  TableComponent,
  FilterModal,
  StockInfo,
  SearchComponent,
} from "./components";
import * as actionTypes from "../../../../store/actionsType";
import { setUserTheme } from "../../../../store/actionsType";

const FunctionalStockLive = (props) => {
  const [showFilter, setShowFilter] = useState(false);
  const [showStockInfo, setShowStockInfo] = useState(false);
  const [stockDetails, setStockDetails] = useState([]);
  const [showStockSearch, setShowStockSearch] = useState(false);

  const [allStockTickerList, setAllStockTickerList] = useState([]);
  const [finalFilterList, setFinalFilterList] = useState([]);
  const [allSectorList, setAllSectorList] = useState([]);

  const [selectedSector, setSelectedSector] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);
  const [activeFilterIndex, setActiveFilterIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [themeLoading, setThemeLoading] = useState(false);
  const [slideAnimation, setSlideAnimation] = useState("");
  const [loadingBgColor, setLoadingBgColor] = useState("");
  const [stockDetailsLoading, setStockDetailsLoading] = useState(false);
  const [filteredList, setFilteredList] = useState(null);
  const [resetStockList, setResetStockList] = useState(false);
  const [valueCleared, setValueCleared] = useState(false);

  useEffect(() => {
    getStockList();
    getSectorList();

    // const savedTheme = localStorage.getItem("theme");
    // if (savedTheme) {
    //   props.onThemeChanged(savedTheme);
    // }
  }, []);

  // this event listener listens to the message sent from react native
  useEffect(() => {
    const handleThemeChange = async (message) => {
      try {
        const { action, payload } = JSON.parse(message.data);
        if (action === "theme") {
          const theme = payload === "dark" ? "Dark" : "Light";
          await props.onThemeChanged(theme);

          localStorage.setItem("theme", theme);
        }
      } catch (error) {
        console.error("Error processing theme message", error);
      }
    };

    window.addEventListener("message", handleThemeChange);

    return () => {
      window.removeEventListener("message", handleThemeChange);
    };
  }, []);

  useEffect(() => {
    if (valueCleared && selectedSector === undefined) {
      if (activeFilter) {
        filterStockListByMarketOverview(activeFilter);
      } else {
        getStockList();
      }
    }
  }, [selectedSector, valueCleared]);

  useEffect(() => {
    if (resetStockList && activeFilter === undefined) {
      if (selectedSector) {
        filterStockListBySector(selectedSector);
      } else {
        getStockList();
      }
      setResetStockList(false);
    }
  }, [activeFilter, resetStockList]);

  const getStockList = () => {
    setLoading(true);
    axiosOrder
      .get("/sidebar/stock-live/stock-list")
      .then((response) => {
        setAllStockTickerList(response.data);
        setFinalFilterList(response.data.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const getSectorList = () => {
    axiosOrder
      .get("/sidebar/stock-live/sector-list")
      .then((response) => {
        setAllSectorList(response.data.data);
      })
      .catch((error) => console.log(error));
  };

  const filterStockListByMarketOverview = (value) => {
    setActiveFilter(value);
    console.log(selectedSector, "here");
    axiosOrder
      .get(`/sidebar/stock-live/stock-list?filter=${value}`)
      .then((response) => {
        if (selectedSector !== null && selectedSector !== undefined) {
          const filteredStockList = response.data.data.filter(
            (item) => item.sector === selectedSector
          );
          setFilteredList(response.data.data);
          setFinalFilterList(filteredStockList);
        } else {
          setFilteredList(response.data.data);
          setFinalFilterList(response.data.data);
        }
        setShowFilter(false);
      })
      .catch((error) => console.log(error));
  };

  const getStockDetails = (value) => {
    setStockDetailsLoading(true);
    axiosOrder
      .get(`/sidebar/stock-live/stock-detail/${value}`)
      .then((response) => {
        setStockDetails(response.data.data);
        setStockDetailsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filterStockListBySector = (value) => {
    setSelectedSector(value);
    if (activeFilter) {
      const filteredStockList = filteredList.filter(
        (item) => item.sector === value
      );
      setFinalFilterList(filteredStockList);
    } else if (value !== null) {
      const filteredStockList = allStockTickerList.data.filter(
        (item) => item.sector === value
      );
      setFinalFilterList(filteredStockList);
    } else {
      setFinalFilterList(allStockTickerList.data);
    }
  };

  const resetToAllStock = () => {
    setActiveFilter(null);
    setResetStockList(true);
    setShowFilter(false);
  };

  const setFilter = () => {
    setShowFilter(!showFilter);
  };

  const onRowClick = (tkr) => {
    getStockDetails(tkr);
    props.onSymbolChanged(tkr);
    setShowStockInfo(true);
  };

  const showStockSearchModal = () => {
    setShowStockSearch(true);
    setSlideAnimation("slide-up");
  };

  const hideStockSearchModal = () => {
    setShowStockSearch(false);
    setSlideAnimation("slide-down");
  };

  const changeActiveFilter = (index, value) => {
    setActiveFilter(value);
    setActiveFilterIndex(index);
  };

  const onValueClear = () => {
    setSelectedSector(null);
    setValueCleared(true);
  };

  const onSearchTickerClick = (tkr) => {
    setShowStockSearch(false);
    getStockDetails(tkr);
    setShowStockInfo(true);
  };

  // if (loading || themeLoading) {
  //   return (
  //     <div className="flex justify-center items-center h-screen" style={loadingBgColor}>
  //       <p>Loading...</p>
  //     </div>
  //   );
  // }

  return (
    <div
      className={`fixed w-full min-[450px]:w-[300px] ${
        props.theme === "Dark" ? "bg-[#131721]" : "bg-[#ffffff]"
      }`}
    >
      <h2
        className={`uppercase hidden min-[450px]:block font-semibold ${
          props.theme === "Dark" ? "text-[#FEBD4E]" : "text-[blue]"
        }`}
      >
        Stock Live
      </h2>

      <div className="min-[450px]:hidden flex justify-between items-center text-[20px] mt-[10px] mx-[20px] h-[45px]">
        <h2
          className={`min-[450px]:hidden font-semibold ${
            props.theme === "Dark" ? "text-white" : "text-black"
          }`}
        >
          Stock Live
        </h2>

        <div className="flex justify-start items-center gap-2">
          <CustomIcon
            iconName={"searchIcon"}
            onClick={showStockSearchModal}
            className={`${
              props.theme === "Dark" ? "fill-white" : "fill-black"
            }`}
          />
        </div>
      </div>

      <main>
        <div className="w-full my-4 mx-[5px] hidden min-[450px]:block">
          <SelectComponent
            onValueSelect={filterStockListBySector}
            data={allSectorList}
            selected={selectedSector}
            onClear={onValueClear}
          />
        </div>

        <TableComponent onClick={onRowClick} data={finalFilterList} />
      </main>

      <div
        className="fixed bottom-5 right-5 min-[450px]:right-16 z-20 flex justify-center cursor-pointer shadow-lg items-center bg-[#1677FF] p-[15px] w-[50px] h-[50px] 2xl:w-[60px] 2xl:h-[60px] rounded-full"
        onClick={setFilter}
      >
        <CustomIcon
          iconName={"filterIcon"}
          className={`w-[22px] h-[22px] 2xl:w-[32px] 2xl:h-[32px] transform transition duration-300 ${
            showFilter ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>

      {showFilter && (
        <FilterModal
          isVisible={showFilter}
          filterClick={filterStockListByMarketOverview}
          isActive={activeFilterIndex}
          setActive={changeActiveFilter}
          reset={resetToAllStock}
        />
      )}

      {showStockInfo && (
        <StockInfo
          onClose={() => setShowStockInfo(false)}
          isVisible={showStockInfo}
          data={stockDetails}
          loading={stockDetailsLoading}
        />
      )}

      {showStockSearch && (
        <SearchComponent
          data={allStockTickerList.data}
          onClose={hideStockSearchModal}
          loading={loading}
          onClick={onSearchTickerClick}
          animation={slideAnimation}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    symbol: state.symbol,
    theme: state.theme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSymbolChanged: (symbol) =>
      dispatch({ type: actionTypes.CHANGE_SYMBOL, symbol: symbol }),
    onThemeChanged: (theme) => dispatch(setUserTheme(theme)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FunctionalStockLive);
