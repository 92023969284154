import React, { useState, useEffect } from 'react';
import CustomIcon from '../../../../../../Assets/CustomIcon';
import { connect } from 'react-redux';

const SearchComponent = ({ data, theme, onClose, loading, onClick }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [filterEmpty, setFilterEmpty] = useState(false);

  useEffect(() => {
    if (data) {
      const filtered = data.filter(item =>
        item.symbol.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.fullName?.toLowerCase()?.includes(searchTerm.toLowerCase())
      );

      if (filtered.length > 0) {
        setFilteredData(filtered);
        setFilterEmpty(false);
      } else {
        setFilteredData([]);
        setFilterEmpty(true);
      }
    }
  }, [data, searchTerm]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  }

  return (
    <>
      <div className={`fixed overflow-x-hidden z-30 w-full h-full top-0 px-[11px] py-[16px] overflow-y-auto  ${theme === 'Dark' ? 'bg-[#131721]' : 'bg-white'}`}>

        {loading &&
          <div className={`centered-element ${theme === 'Dark' ? 'text-white' : 'text-black'}`}>
            Loading...
          </div>
        }

        {filterEmpty &&
          <div className={`centered-element ${theme === 'Dark' ? 'text-[#9BA0A4]' : 'text-gray'}`}>
            Can't find the company you are looking for..
          </div>
        }

        <div className={`fixed flex flex-row items-center justify-center w-full h-[50px] top-0 px-3 ${theme === 'Dark' ? 'bg-[#131721]' : 'bg-white'}`}>
          <input
            type="text"
            placeholder="What company are you looking for?"
            value={searchTerm}
            onChange={handleChange}
            className={`w-[85vw] text-sm outline-none pt-5 pb-2 mr-4 rounded-none border-b-[1px] bg-transparent ${theme === 'Dark' ? 'border-white text-white' : 'border-black text-black'}`}
          />
          <CustomIcon
            iconName={'closeIcon'}
            onClick={onClose}
            className={`mr-2 ${theme === 'Dark' ? 'fill-white' : 'fill-black'}`}
          />
        </div>

        <ul className='mt-10'>
          {filteredData?.map((item, index) => (
            <li
              key={index}
              onClick={() => onClick(item.symbol)}
              className={`flex flex-col px-3 py-4 border-b-[1px] ${theme === 'Dark' ? 'border-[#333333]' : 'border-gray'}`}
            >
              <p className={`font-bold text-[16px] ${theme === 'Dark' ? 'text-white' : 'text-black'}`}>{item.symbol}</p>
              <p className={`text-[12px] ${theme === 'Dark' ? 'text-[#9BA0A4]' : 'text-gray'}`}>{item.fullName}</p>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
  };
};

export default connect(mapStateToProps)(SearchComponent);