import React from 'react'
import CustomIcon from '../../../../../../../Assets/CustomIcon';

const Updates = ({ data }) => {

  return (
    <div className={`flex flex-row items-start px-[8px] py-[8px] w-full min-h-[76px] bg-[#FCF5EF] rounded-lg`}>
      <span className={`flex justify-center items-center rounded-full bg-[#EEEEEE] p-[3px] mr-[7px]`}>
        <CustomIcon iconName={'announceIcon'} />
      </span>
      <div className='flex flex-col items-start'>
        <p className='text-[14px] text-black font-semibold leading-normal mb-[6px]'>{data.title}</p>
        <p className='text-[12px] font-normal text-[#424242]'>{data.body}</p>

        <span className="w-full flex justify-end text-[#7C8287] text-[12px] font-light italic">
          {data.date}
        </span>

      </div>
    </div>
  )
}

export default Updates