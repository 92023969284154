import React from 'react'
import CustomIcon from '../../../../../../../Assets/CustomIcon';

const BrokerInsights = ({ data }) => {
  return (
    <div className={`flex flex-row items-start px-[8px] py-[8px] w-full min-h-[76px] bg-[#FFF2F3] rounded-lg`}>
      <span className={`bg-[#E9E9F5] flex justify-center items-center rounded-full p-[3px] mr-[7px]`}>
        <CustomIcon iconName={'glassesIcon'} />
      </span>
      <div className='flex flex-col items-start'>
        <p className='text-[14px] text-black font-semibold leading-normal mb-[6px]'>Broker Insights</p>
        <p className='text-[12px] font-normal text-[#424242]' dangerouslySetInnerHTML={{ __html: data.description }}></p>
        <p className='text-[12px] font-light italic text-[#7C8287] mt-[10px]'>{data.body}</p>
      </div>
    </div>
  )
}

export default BrokerInsights;