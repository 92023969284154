import { Button, Collapse, Result } from "antd";
import React from 'react';
import "./notfound.css";

const { Panel } = Collapse;


const NotFound = () => {
    const callback = (key) => {
        console.log(key);
    }
    return (
        <div style={ {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px"
        } }>
            <Result
                status="403"
                style={ { height: "200px !important" } }
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
                // extra={ <Button type="primary">Contact SMTM Capital Admin</Button> }
            />
            <div>
                <Collapse defaultActiveKey={ [ '1', "2" ] } onChange={ callback }>
                    <Panel header="Why am I seeing this message ?" key="1">
                        <p>You are seeing this message because either your token has expired or your credentials have
                            been used to log in from another device. </p>
                    </Panel>
                    <Panel key={ "2" } header={ "What should I do now ? " }>
                        <div>Please ensure that your credentials are secure and safe with you. Do not share your
                            credentials with others.<br/>
                            <li>
                                <ul>Close this browser window, go to systemxlite, press CTRL + F5 on Windows or CMD + F5
                                    on Mac to refresh your browser and retry.
                                </ul>
                                <ul>If you still see this error message, go to systemxlite, press CTRL + F5 on Windows
                                    or CMD + F5 on Mac to refresh your browser, log out of systemxlite, log in again and
                                    retry.
                                </ul>
                                <ul>If the issue still remains, you will need to clear your browser’s cache by going to
                                    your browser’s history and clearing stored cache data.
                                </ul>
                            </li>
                            If you still see this problem, please contact SMTM Capital at <span style={ {
                                fontWeight: "bold",
                                textDecoration: "underline"
                            } }> 01-4540783 </span> or email at <span
                                style={ {
                                    fontWeight: "bold",
                                    textDecoration: "underline"
                                } }> admin@smtmcapital.com.np </span>
                        </div>
                    </Panel>

                </Collapse>
            </div>
        </div>
    )
}

export default NotFound;
